import Cookies from 'js-cookie'

const TokenKey = 'erp-access-token';
var inFifteenMinutes = new Date('9999-12-31'); // 由后端控制永不过期
let myDomain = document.domain.split('.').splice(-2).join('.')

export function getToken() {
    return Cookies.get(TokenKey)
}

export function setToken(token) {
    return Cookies.set(TokenKey, token, {expires: inFifteenMinutes, domain: myDomain})
}

export function removeToken() {
    //sessionStorage.removeItem('erp_token');
    //全部清空
    sessionStorage.clear();
    localStorage.clear();
    Cookies.set(TokenKey, '', {expires: inFifteenMinutes, domain: myDomain});
    return Cookies.remove(TokenKey)
}

export function clearCookie() {
    var date = new Date();
    date.setTime(date.getTime() - 10000);

    var keys = document.cookie.match(/[^ =;]+(?==)/g);
    if (keys) {
        for (var i = keys.length; i--;) {
            document.cookie = keys[i] + "=0;path=/;expires=" + date.toUTCString();
            document.cookie =
                keys[i] +
                "=0;path=/;domain=" +
                document.domain +
                ";expires=" +
                date.toUTCString();
            Cookies.set(TokenKey, '', {expires: inFifteenMinutes, domain: myDomain})
        }
    }
}
export function getCookie(key) {
    return Cookies.get(key);
}
export function setCookie(key,value,expires) {
    return Cookies.set(key, value, {expires: expires ? expires : inFifteenMinutes, domain: myDomain})

}
export function clearCookieCom(key) {
    setCookie(key, "", -1);
}
export async function keepUserTagList(personalCenter) {
    let userId = JSON.parse(sessionStorage.getItem('avuex-userInfo')).content.userId;
    const key = (userId + "" + personalCenter);
    let userIdTagList = JSON.parse(localStorage.getItem('userIdTagList'));
    if (!userIdTagList || userIdTagList.length == 0) {
        userIdTagList = [];
    }
    //现在的列表缓存
    let currentUserIdTagList = userIdTagList.find(f => f.flagkey === key);
    let tagList = sessionStorage.getItem('avuex-tagList');
    let newTagList = {};
    if (tagList) {
        newTagList = JSON.parse(tagList);
        newTagList.content = newTagList.content.filter(o => (personalCenter && o.value != "/wel/index") || (!personalCenter && o.value != "/personal/information/info/index"));
    }
    if (currentUserIdTagList) {//当前租户存在更新
        currentUserIdTagList.tagList = JSON.stringify(newTagList);
    } else {//新增
        userIdTagList.push({
            flagkey: key,
            tagList: JSON.stringify(newTagList),
        })
    }
    localStorage.setItem('userIdTagList', JSON.stringify(userIdTagList));
    localStorage.setItem('isSwitch', false);
}